import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Natale = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Appuntamento di Natale
            </h1>
            <br />
            <h4>Il teatro come mezzo di unione</h4>
            <br />
            <h6>30 secondi di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/natale.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h3 className="arancione arima-bold">IL PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            Per noi dell’associazione il{" "}
            <span className="fucsia">periodo natalizio</span> è un momento
            perfetto per{" "}
            <span className="fucsia">stare insieme e fare beneficenza.</span>
            <br />
            <br />
            Ecco perché ogni anno organizziamo uno{" "}
            <span className="fucsia">spettacolo teatrale</span> presso il teatro
            Moderno di Latina, la nostra città.
            <br />
            <br />
            Il <span className="fucsia">ricavato</span> dell’evento ha come
            scopo quello di contribuire, ogni anno, a finanziare le nostre{" "}
            <span className="fucsia">attività solidali</span>.
            <br />
            <br />
            Inoltre, la serata rappresenta ogni volta un’occasione per gioire di
            tutti gli aiuti che siamo riusciti a dare e tutti i{" "}
            <span className="fucsia">traguardi</span> che abbiamo raggiunto
            insieme. Non solo: è anche un momento per discutere di tutto ciò che
            abbiamo intenzione di fare, con chi ci sostiene, l’anno successivo.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              Fino ad ora l’appuntamento di Natale ci ha permesso di raccogliere
              1200 € che sono stati reinvestiti in progetti di beneficenza
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Natale;
