import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./pagine/Home/Home";
import ChiSiamo from "./pagine/ChiSiamo/ChiSiamo";
import NavBar from "./componenti/NavBar";
import Contatti from "./pagine/Contatti/Contatti";
import CosaPuoiFare from "./pagine/CosaPuoiFare/CosaPuoiFare";
import Progetti from "./pagine/Progetti/Progetti";
import CinquePerMille from "./pagine/CosaPuoiFare/CinquePerMille";
import Ludoteca from "./pagine/CosaPuoiFare/Ludoteca";
import Uova from "./pagine/Progetti/Articoli/Uova";
import Saint from "./pagine/Progetti/Articoli/Saint";
import UnAngelo from "./pagine/Progetti/Articoli/UnAngelo";
import Meningite from "./pagine/Progetti/Articoli/Meningite";
import Sorriso from "./pagine/Progetti/Articoli/Sorriso";
import Game from "./pagine/Progetti/Articoli/Game";
import Natale from "./pagine/Progetti/Articoli/Natale";
import Ospedale from "./pagine/Progetti/Articoli/Ospedale";
import Ristrutturazione from "./pagine/Progetti/Articoli/Ristrutturazione";
import ProntoSoccorso from "./pagine/Progetti/Articoli/ProntoSoccorso";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/chi-siamo",
    element: <ChiSiamo />,
  },
  {
    path: "/contatti",
    element: <Contatti />,
  },
  {
    path: "/cosa-puoi-fare-tu",
    element: <CosaPuoiFare />,
  },
  {
    path: "/i-nostri-progetti",
    element: <Progetti />,
  },
  {
    path: "/cinque-per-mille",
    element: <CinquePerMille />,
  },
  {
    path: "/ludoteca",
    element: <Ludoteca />,
  },
  {
    path: "/articolo/uova-della-solidarieta",
    element: <Uova />,
  },
  {
    path: "/articolo/saint-damien",
    element: <Saint />,
  },
  {
    path: "/articolo/aiutiamo-un-angelo",
    element: <UnAngelo />,
  },
  {
    path: "/informiamo-sulla-meningite",
    element: <Meningite />,
  },
  {
    path: "/articolo/la-giornata-del-sorriso",
    element: <Sorriso />,
  },
  {
    path: "/articolo/pediatric-simulations-game",
    element: <Game />,
  },
  {
    path: "/articolo/appuntamento-di-natale",
    element: <Natale />,
  },
  {
    path: "/articolo/raccolta-fondi-ospedale",
    element: <Ospedale />,
  },
  {
    path: "/articolo/ristrutturazione-centro-vaccinale",
    element: <Ristrutturazione />,
  },
  {
    path: "/articolo/pronto-soccorso-pediatrico",
    element: <ProntoSoccorso />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NavBar />
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
