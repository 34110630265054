import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Ospedale = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Raccolta fondi per il nostro ospedale
            </h1>
            <br />
            <h4>Combattiamo insieme la pandemia</h4>
            <br />
            <h6>2 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/fondiospedale.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Nel corso del 2020, abbiamo lanciato una campagna di raccolta fondi
            a favore dell’ospedale Santa Maria Goretti di Latina, con
            l'obiettivo di supportare concretamente i medici, gli infermieri e
            tutto il personale sanitario che si trovavano a fronteggiare la
            pandemia da Covid-19 in prima linea.
            <br />
            <br />
            La raccolta fondi è stata promossa attraverso diverse iniziative,
            come eventi e donazioni online e ha permesso di raccogliere una
            somma considerevole di denaro.
            <br />
            <br />
            I fondi raccolti sono stati utilizzati per acquistare attrezzature
            mediche essenziali per il personale sanitario, come mascherine,
            camici monouso, occhiali protettivi e alcol disinfettante. Inoltre,
            una parte del denaro è stata destinata all'acquisto di
            apparecchiature mediche utili per la diagnosi e il trattamento dei
            pazienti affetti da Covid-19.
            <br />
            <br />
            La raccolta fondi è stata un grande successo e ci ha permesso di
            supportare concretamente l'ospedale Santa Maria Goretti di Latina e
            il suo personale sanitario nella lotta contro la pandemia.
            <br />
            <br />
            Siamo grati a tutti coloro che hanno contribuito alla campagna e che
            hanno permesso di raccogliere questi fondi.
          </h6>
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Ospedale;
