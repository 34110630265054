import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Game = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Pediatric Simulations Game
            </h1>
            <br />
            <h4>Impariamo a salvare la vita dei bambini</h4>
            <br />
            <h6>1 minuto di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/game.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Tutte le attività alle quali partecipiamo hanno come obiettivo
            quello di tutelare la salute dei nostri bambini e bambine.
            L’assistenza medica di qualità deve partire dai professionisti
            sanitari, ma anche noi possiamo imparare qualcosa. E chi può
            insegnarci cosa fare in situazioni di emergenza, se non degli
            esperti di calibro internazionale?
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">IL PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            I “Pediatric Simulation Games” sono le{" "}
            <span className="fucsia">olimpiadi dell’emergenza pediatrica</span>{" "}
            ideate nel 2017 dal professore Riccardo Lubrano, primario di
            Pediatria e Neonatologia dell’area Pontina e docente presso
            l’università “La Sapienza” di Roma.
            <br />
            <br />
            Si tratta di un <span className="fucsia">torneo</span> vero e
            proprio che vede sfidarsi decine di team internazionali, composti da{" "}
            <span className="fucsia">specializzandi</span> in pediatria di
            molteplici atenei. La sfida consiste nell’affrontare un caso clinico
            di un paziente pediatrico critico.
            <br />
            <br />I giochi si svolgono in <span className="fucsia">3 fasi</span>
            :
            <ul>
              <li>
                fase 1: si descrivono al team le condizioni di arrivo del
                bambino in pronto soccorso
              </li>
              <li>
                fase 2: il gruppo di medici interviene per stabilizzare il
                paziente
              </li>
              <li>
                fase 3: si analizzano con un formatore le tappe della
                simulazione per arrivare a migliorarla.
              </li>
            </ul>
            <br />
            <br />
            Lo scopo di questo progetto è quello di{" "}
            <span className="fucsia">diffondere</span>, attraverso il gioco, un’
            <span className="fucsia">assistenza di alto livello</span> comune a
            tutti i centri ospedalieri italiani. Il desiderio più grande è che
            ogni bambino e bambina, ovunque si trovi, abbia la stessa{" "}
            <span className="fucsia">qualità assistenziale</span>.
            <br />
            <br />
            In qualità di associazione, abbiamo deciso di partecipare fornendo
            supporto organizzativo all’evento.
            <br />
            <br />
            Durante le giornate dei Pediatric Simulation Games viene svolto
            anche l’incontro “<span className="fucsia">Manovra della vita</span>
            ”, organizzata dalla SIMEUP, la società di medicina d’emergenza e
            urgenza pediatrica.
            <br />
            <br />
            L’obiettivo di questo evento è quello di insegnare ai familiari{" "}
            <span className="fucsia">come comportarsi</span> nel caso di un
            episodio così frequente e drammatico come il soffocamento.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              L’associazione Alessia e i suoi Angeli ha partecipando investendo
              1000 € per assistere i partecipanti
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Game;
