import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../componenti/Footer";
import CardContatti from "../../componenti/CardContatti";

const Contatti = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="primo-div-contatti text-center">
          <Container>
            <h1 className="arima-bold testo-titolo-home">
              Se ti va, contattaci.
              <br />
              Saremo lieti di parlare con te
            </h1>
          </Container>
        </div>
        <div className="secondo-div-contatti text-center">
          <Container>
            <h4 className="arima-medium">I NOSTRI CONTATTI</h4>
            <br />
            <br />
            <Row>
              <Col xs={12} md={6}>
                <h5 className="arima-medium">TELEFONO</h5>
                <h3 className="arima-bold">3755136338</h3>
                <br />
              </Col>
              <Col xs={12} md={6}>
                <h5 className="arima-medium">EMAIL</h5>
                <h3 className="arima-bold">alessiaeisuoiangeli@libero.it</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="terzo-div-contatti text-center background-fiori">
          <Container>
            <h4 className="arima-medium">LA NOSTRA SEDE</h4>
            <br />
            <h1 className="arima-bold arancione">
              Per un incontro in presenza, vienici a trovare
              <br />
              nella nostra sede
            </h1>
            <br />
            <Row>
              <Col xs={12} md={6}>
                <h5 className="arima-medium">INDIRIZZO</h5>
                <h3 className="arima-bold">
                  Largo Peri zona Q4
                  <br />
                  Latina, 04100
                </h3>
                <br />
              </Col>
              <Col xs={12} md={6}>
                <h5 className="arima-medium">ORARI</h5>
                <h3 className="arima-bold">
                  Dal lunedì al venerdì,
                  <br />
                  dalle 10.00 alle 13.00
                </h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="quarto-div-contatti text-center">
          <Container>
            <h4 className="arima-medium">VUOI LASCIARCI UN MESSAGGIO?</h4>
            <br />
            <h1 className="arima-bold arancione">Compila il form qui sotto</h1>
            <br />
            <h5 className="interlinea-50">
              Tutti i campi sono obbligatori. Non preoccuparti, non cederemo i
              tuoi dati a nessuno e non
              <br />
              useremo la tua email per inviarti comunicazioni promozionali
            </h5>
            <center>
              <br />
              <CardContatti />
            </center>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contatti;
