import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../componenti/Footer";
import CardContatti from "../../componenti/CardContatti";
const Ludoteca = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="primo-div-ludoteca text-center">
          <Container>
            <h1 className="text-white arima-bold testo-titolo-home">
              Trasforma il tuo evento in
              <br /> un'attività di beneficenza
            </h1>
            <br />
            <br />
            <h4 className="text-white">
              Feste di compleanno, riunioni e rinfreschi: qualsiasi evento tu
              voglia organizzare può fare del bene
              <br /> se fatto nella nostra ludoteca
            </h4>
          </Container>
        </div>
        <div className="secondo-div-ludoteca text-center background-fiori">
          <Container>
            <h1 className="arima-medium arancione">
              Ogni nostra azione, anche affittare una sala per gli eventi, può
              regalare dei sorrisi
            </h1>
            <br />
            <h5 className="interlinea-50">
              Scegliere di festeggiare il compleanno della tua bambina o il
              rinfresco di Natale nella ludoteca di
              <br /> Alessia e i suoi Angeli ha un impatto positivo non solo su
              di te, ma anche sulle altre persone
            </h5>
            <br />
            <br />
            <h4 className="arima-medium">LA LUDOTECA</h4>
            <br />
            <br />
            <Row>
              <Col xs={12} md={4}>
                <img
                  src="/immagini/icona1.png"
                  className="mb-3"
                  alt="righello"
                />
                <h4 className="mb-5">Ampia sala di 120 mq</h4>
              </Col>
              <Col xs={12} md={4}>
                <img
                  src="/immagini/icona2.png"
                  className="mb-3"
                  alt="persone"
                />
                <h5 className="mb-5">Può ospitare fino a 45 persone</h5>
              </Col>
              <Col xs={12} md={4}>
                <img src="/immagini/icona3.png" className="mb-3" alt="luce" />
                <h5 className="mb-5">Con luci, stereo e proiettore</h5>
              </Col>
              <Col xs={12} md={4}>
                <img
                  src="/immagini/icona4.png"
                  className="mb-3"
                  alt="pavimento"
                />
                <h5 className="mb-5">
                  Pavimento in parquet, tavoli, sedie, puff e servizi
                </h5>
              </Col>
              <Col xs={12} md={4}>
                <img
                  src="/immagini/icona5.png"
                  className="mb-3"
                  alt="aria condizionata"
                />
                <h5 className="mb-5">
                  Aria condizionata e termo riscaldamento
                </h5>
              </Col>
              <Col xs={12} md={4}>
                <img
                  src="/immagini/icona6.png"
                  className="mb-3"
                  alt="castello incantato"
                />
                <h5 className="mb-5">
                  Ricrea l'atmosfera di un castello incantato
                </h5>
              </Col>
            </Row>
            <br />
            <br />

            <h4 className="arima-medium">LE FOTO</h4>
            <br />
            <center>
              <br />
              <img
                src="/immagini/ludoteca1.png"
                className="immagini-cinquepermille mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <img
                src="/immagini/ludoteca2.png"
                className="immagini-cinquepermille mx-4 mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <img
                src="/immagini/ludoteca2.png"
                className="immagini-cinquepermille mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <br />
            </center>
          </Container>
        </div>
        <div className="terzo-div-ludoteca text-center">
          <Container>
            <h1 className="arima-medium arancione">
              Se lo desideri, possiamo fornirti il catering
              <br /> e l'animazione
            </h1>
            <br />
            <h5 className="interlinea-50">
              E per quanto riguarda il <span className="fucsia">prezzo</span>,
              questo dipende dalle tue esigenze. <br />
              Contattaci: insieme possiamo elaborare un preventivo per te.
            </h5>
            <br />
            <h5 className="interlinea-50">
              Tutti i campi sono obbligatori. Non preoccuparti, non cederemo i
              tuoi dati a nessuno e non
              <br /> useremo la tua email per inviarti comunicazioni
              promozionali
            </h5>
            <br />
            <center>
              <CardContatti />
            </center>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ludoteca;
