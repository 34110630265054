import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../componenti/Footer";
import UltimoDivArticolo from "./Articoli/UltimoDivArticolo";

const Progetti = () => (
  <>
    <div className="wrapper">
      <div className="primo-div-progetti text-center">
        <Container className="text-center">
          <h1 className="text-white arima-bold testo-titolo-home">
            I nostri progetti
          </h1>
          <br />
          <br />
          <h4 className="text-white interlinea-50">
            Scopri cosa abbiamo potuto fare grazie alle donazioni di tantissime
            persone che hanno scelto di
            <br />
            contribuire alla nostra causa
          </h4>
        </Container>
      </div>
      <div className="secondo-div-progetti background-fiori">
        <Container className="text-center">
          <h1 className="arima-bold arancione">
            Dal 2007 garantiamo cure e beni di prima
            <br />
            necessità a bambine e bambini, in Italia e all'estero
          </h1>
          <br />
          <h5 className="interlinea-50">
            Latina, Roma, Emilia Romagna, Madagascar, Moldavia, Tanzania.
            <br />
            La solidarietà non conosce barriere
          </h5>
          <br />
          <br />
          <br />
          <h4 className="arima-medium">I NOSTRI PROGETTI</h4>
          <br />
          <br />
        </Container>
        <Container>
          <Row>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/card-uova.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Le uova della solidarietà</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    La scuola è uno degli ambienti più importanti per la
                    crescita e lo sviluppo di bambine e bambini. Per questo
                    motivo è giusto che noi...
                  </Card.Text>
                  <Link
                    to="/articolo/uova-della-solidarieta"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/damien.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-medium mb-4">
                    <h4>Aiutiamo l’ospedale Saint Damien</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Noi dell’associazione “Alessia e i suoi Angeli” abbiamo un
                    obiettivo: portare solidarietà e dare sostegno, con aiuti
                    concreti...
                  </Card.Text>
                  <Link
                    to="/articolo/saint-damien"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione  mb-5">
                <Card.Img src="/immagini/articoli/card/aiutiamo_un_angelo.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Aiutiamo un angelo</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    In Italia tantissimi genitori non possono permettersi di
                    pagare le cure che i propri piccoli e le proprie piccole
                    necessitano. Nel caso...
                  </Card.Text>
                  <Link
                    to="/articolo/aiutiamo-un-angelo"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/meningite.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Informiamo sulla meningite</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Il tema della meningite è fortemente sentito dalla nostra
                    associazione. è stata proprio questa malattia, infatti, a
                    portarci...
                  </Card.Text>
                  <Link
                    to="/informiamo-sulla-meningite"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/giornata_del_sorriso.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>La giornata del sorriso</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Uno degli obiettivi dell’associazione “Alessia e i suoi
                    Angeli” è quello di far spuntare un bel sorriso sulle bocche
                    di più bambini e...
                  </Card.Text>
                  <Link
                    to="/articolo/la-giornata-del-sorriso"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/game.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Pediatric Simulations Game</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Tutte le attività alle quali partecipiamo hanno come
                    obiettivo quello di tutelare la salute dei nostri bambini...
                  </Card.Text>
                  <Link
                    to="/articolo/pediatric-simulations-game"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4" />
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/natale.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Appuntamento di Natale</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Per noi dell’associazione il periodo natalizio è un momento
                    perfetto per stare insieme e fare beneficenza....
                  </Card.Text>
                  <Link
                    to="/articolo/appuntamento-di-natale"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4" />
          </Row>
          <br />
          <br />
          <br />
          <center>
            <h4 className="arima-medium">PROGETTI CONCLUSI</h4>
          </center>
          <br />
          <br />
          <br />
          <Row>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/fondiospedale.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Raccolta fondi per il nostro ospedale</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    Nel corso del 2020, abbiamo lanciato una campagna di
                    raccolta fondi a favore dell’ospedale Santa Maria Goretti di
                    Latina, con...
                  </Card.Text>
                  <Link
                    to="/articolo/raccolta-fondi-ospedale"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/centro_vaccinale.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Ristrutturazione centro vaccinale</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    E se ti dicessimo che la cioccolata può migliorare la
                    scuola? La scuola è un ambiente fondamentale per i
                    bambini...
                  </Card.Text>
                  <Link
                    to="/articolo/ristrutturazione-centro-vaccinale"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <Card className="card-bordo-arancione mb-5">
                <Card.Img src="/immagini/articoli/card/pronto-soccorso.webp" />
                <Card.Body>
                  <br />
                  <Card.Title className="arima-bold mb-4">
                    <h4>Pronto soccorso pediatrico</h4>
                  </Card.Title>
                  <Card.Text className="h5 interlinea-30 mb-4">
                    La ristrutturazione del centro vaccinale di Latina è uno dei
                    progetti di cui siamo più fieri perché ha permesso di
                    rendere un...
                  </Card.Text>
                  <Link
                    to="/articolo/pronto-soccorso-pediatrico"
                    className="fucsia"
                    style={{ textDecoration: "none" }}
                  >
                    <h5>LEGGI</h5>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <center>
            <h5>
              Se vuoi conoscere gli altri progetti conclusi, scarica il{" "}
              <a
                href="/documenti/conclusi.pdf"
                target="_blank"
                rel="noreferrer"
              >
                pdf
              </a>
            </h5>
          </center>
        </Container>
      </div>
      <UltimoDivArticolo />
    </div>
    <Footer />
  </>
);

export default Progetti;
