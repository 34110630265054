import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Uova = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Le uova della solidarietà
            </h1>
            <br />
            <h4>
              E se ti dicessimo che la cioccolata può migliorare la scuola?
            </h4>
            <br />
            <h6>5 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/cosa_puoi_fare.webp"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            La scuola è uno degli ambienti più importanti per la crescita e lo
            sviluppo di bambine e bambini. Per questo motivo è giusto che noi
            adulti ce ne prendiamo cura, preoccupandoci di fornire agli istituti
            tutto ciò che serve a rendere l'ambiente migliore.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">Il PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            Ogni anno, durante il periodo pasquale, noi dell'associazione
            "Alessia e i suoi Angeli" portiamo avanti una raccolta fondi
            attraverso la{" "}
            <span className="fucsia">vendita di uova di cioccolato.</span>
            <br />
            <br />
            La <span className="fucsia">raccolta fondi</span> avviene nelle{" "}
            <span className="fucsia">scuole</span> dell'infanzia, primarie e
            secondarie di Latina e provincia, naturalmente con l'autorizzazione
            dei dirigenti scolastici e con l'aiuto di docenti e genitori.
            <br />
            <br />I soldi raccolti dalla vendita delle uova (al netto delle
            spese) vengono{" "}
            <span className="fucsia">reinvestiti interamente</span> in progetti
            all'interno delle scuole stesse. Chi compra le uova della nostra
            Associazione, può scegliere anche di destinare il ricavato ad altri
            progetti solidali portati avanti dall'Associazione.
            <br />
            <br />
            Purtroppo con la pandemia abbiamo dovuto interrompere questa
            iniziativa, ma dal 2023 siamo pronti a organizzarla nuovamente.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">I RISULTATI</h3>
          <br />
          <h5 className="fucsia arima-bold">2019</h5>
          <br />
          <h6 className="interlinea-30">
            L'I.C. Giovanni Cena di Latina e I.C. Borgo Faiti Scuola Primaria
            San Michele hanno destinato il ricavato della vendita delle uova di
            Pasqua all'acquisto di{" "}
            <span className="fucsia">due PC portatili</span> che saranno messi a
            disposizione degli alunni per approfondimenti e lavori di gruppo.
            <br />
            <br />
            La scuola primaria Goldoni del I.C. G. Giuliani ha comprato{" "}
            <span className="fucsia">6 tablet</span> Samsung Tab A T510/2019.
            <br />
            <br />
            L'I.C. Prampolini – Borgo Montello, Borgo Carso, Chiesuola, Borgo
            Piave, I.C. Borgo Faiti Plesso Borgo Faiti, Borgo Grappa, San
            Michele e I.C. Leonardo da Vinci/Gianni Rodari Scuola dell'infanzia
            hanno acquistato{" "}
            <span className="fucsia">materiale didattico.</span>
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2018</h5>
          <br />
          <h6 className="interlinea-30">
            L'I.C Collodi di Anzio ha acquistato un{" "}
            <span className="fucsia">palco</span> per lo svolgimento delle
            recite scolastiche, mentre la scuola di Via Cilea a Latina ha scelto
            di investire il ricavato in{" "}
            <span className="fucsia">materiale didattico.</span>
            <br />
            <br />
            L'I.C. Don Milani ha potuto acquistare delle panche e l'I.C Leonardo
            da Vinci/Rodari{" "}
            <span className="fucsia">due LIM, un videoproiettore</span>, degli{" "}
            <span className="fucsia">strumenti per l'aula di scienze</span> e
            per la <span className="fucsia">robotica.</span>
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2017</h5>
          <br />
          <h6 className="interlinea-30">
            La scuola dell'infanzia “Giovanni Paolo II” IC G. Giuliano ha scelto
            di utilizzare i soldi provenienti dalla vendita delle uova per
            migliorare l'<span className="fucsia">arredamento</span> delle aule
            didattiche.
            <br />
            <br />
            L'I.C. Don Milani ha acquistato dei materiali per l'
            <span className="fucsia">impianto di irrigazione</span> per la
            scuola dell’infanzia in Via Cilea.
            <br />
            <br />
            L'I.C. n.12 Plesso B.go S. Michele ha potuto organizzare lo{" "}
            <span className="fucsia">spettacolo di fine anno</span> di tutte le
            classi presso il teatro D’Annunzio.
            <br />
            <br />
            L'I.C. Natale Prampolini e L. Da Vinci-Rodari hanno acquistato del{" "}
            <span className="fucsia">materiale didattico</span> e una{" "}
            <span className="fucsia">LIM.</span>
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2016</h5>
          <br />
          <h6 className="interlinea-30">
            Durante questo anno, grazie alla vendita delle uova della
            solidarietà, sono stati finanziati{" "}
            <span className="fucsia">20 progetti</span> nelle scuole di Latina
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2015</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2015 la raccolta fondi ha permesso di realizzare numerosi
            progetti all’interno delle scuole di Latina. Sono stati acquistati{" "}
            <span className="fucsia">
              libri, computer, lavagne LIM, materiale didattico e giochi
            </span>{" "}
            per i più piccoli.
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2014</h5>
          <br />
          <h6 className="interlinea-30">
            La scuola primaria di Borgo Pasubio ha scelto di reinvestire il
            ricavato della raccolta fondi in{" "}
            <span className="fucsia">materiali per la LIM</span> e per il{" "}
            <span className="fucsia">laboratorio multimediale.</span> Questi
            acquisti sono stati finalizzati a potenziare l’apprendimento e
            l’inclusione.
            <br />
            <br />
            Il plesso Verga ha acquistato una{" "}
            <span className="fucsia">stampante a colori</span> per documentare
            tutte le attività dei laboratori di informatica svolte dai ragazzi e
            dalle ragazze.
            <br />
            <br />
            La scuola primaria di Borgo Piave ha acquistato una{" "}
            <span className="fucsia">videocamera</span> completa di{" "}
            <span className="fucsia">memory card, borsa e garanzia</span> per 4
            anni, più <span className="fucsia">toner</span> per le stampanti.
            <br />
            <br />
            La scuola secondaria di primo grado G. Cena, a Latina, ha comprato
            una <span className="fucsia">televisione e un lettore dvd</span>,
            utili all’attività didattica.
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2013</h5>
          <br />
          <h6 className="interlinea-30">
            Gli istituti Prampolini e Borgo Faiti hanno scelto di utilizzare i
            ricavi provenienti dalla raccolta fondi in{" "}
            <span className="fucsia">materiale didattico.</span>
            <br />
            <br />
            L’istituto G. Giuliano e la scuola G. Rodari hanno acquistato ognuna
            un <span className="fucsia">impianto di amplificazione</span> per
            l’aula magna.
            <br />
            <br />
            L’Istituto Cena ha potuto comprare{" "}
            <span className="fucsia">30 registratori</span> per le attività
            linguistiche e un <span className="fucsia">forno e stampini</span>{" "}
            per dilettarsi a cucinare con i bambini e le bambine della scuola
            dell’infanzia.
            <br />
            <br />
            L’Istituto Verga di Pontinia ha scelto di investire il ricavato
            della raccolta fondi in un'<span className="fucsia">
              altalena
            </span>{" "}
            per il parco giochi dei più piccoli e in{" "}
            <span className="fucsia">libri nuovi</span> per la biblioteca.
          </h6>
          <br />
          <h5 className="fucsia arima-bold">2012</h5>
          <br />
          <h6 className="interlinea-30">
            La vendita delle uova della solidarietà ha permesso di realizzare{" "}
            <span className="fucsia">piccoli progetti</span> nelle scuole
            elementari e materne della città di Latina.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              Dal 2012 al 2019 sono stati raccolti e reinvestiti in attività di
              beneficenza 30.000 €
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Uova;
