import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Sorriso = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              La giornata del sorriso
            </h1>
            <br />
            <h4>Parola chiave: prevenzione</h4>
            <br />
            <h6>2 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/giornata_del_sorriso.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Uno degli obiettivi dell’associazione “Alessia e i suoi Angeli” è
            quello di far spuntare un bel sorriso sulle bocche di più bambini e
            bambine possibili. Non solo in senso simbolico, ma anche letterale.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">IL PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            “La giornata del sorriso” è un’iniziativa riguardante la{" "}
            <span className="fucsia">prevenzione</span>, il cui obiettivo è
            quello di far capire ai più piccoli l’importanza di una{" "}
            <span className="fucsia">corretta igiene orale</span>.
            <br />
            <br />
            Durante queste giornate, organizzate nelle scuole e nelle piazze,
            bambini e bambine possono partecipare a divertenti{" "}
            <span className="fucsia">attività</span> volte a trasmettere loro
            l’importanza della prevenzione. Non solo: ogni anno, in occasione
            della giornata, vengono fatte centinaia di{" "}
            <span className="fucsia">visite odontoiatriche gratuite</span>.
            <br />
            <br />
            Tutto questo è possibile grazie alla collaborazione con il
            Dipartimento di Scienze Odontostomatologiche e Maxillo-Facciali, il
            Dipartimento di Scienze Biotecnologie medicochirurgiche, il Corso di
            laurea di Igiene Dentale della «Sapienza» Università di Roma, l’AUSL
            di Latina ed il patrocinio del Comune di Latina.
            <br />
            <br />
            Anche se il sorriso è al centro della giornata, organizziamo anche
            visite{" "}
            <span className="fucsia">
              oculistiche e pediatriche di screening
            </span>{" "}
            su ipertensione, obesità e patologie autoimmuni.
            <br />
            <br />
            Il 2023 sarà un anno pieno di attività e sorrisi.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">I RISULTATI</h3>
          <br />
          <h5 className="fucsia arima-bold">2019</h5>
          <br />
          <h6 className="interlinea-30">
            I protagonisti di queste giornate sono stati i bambini e le bambine
            delle scuole elementari delle classi seconde, appartenenti ai{" "}
            <span className="fucsia">circoli didattici di Latina.</span>
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2018</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2018, l’iniziativa è stata realizzata anche grazie al contributo
            di Catalent attraverso la piattaforma di Charity “Yourcause” e del
            Centro Commerciale Latina fiori che ci ha ospitato.
            <br />
            <br />
            Nel corso delle due giornate sono state effettuate oltre{" "}
            <span className="fucsia">
              300 visite odontoiatriche e pediatriche
            </span>{" "}
            di screening su ipertensione, obesità e patologie autoimmuni
            completamente gratuite.
            <br />
            <br />
            In totale sono stati visitati{" "}
            <span className="fucsia">200 bambini e bambine</span> provenienti
            dalle scuole primarie e secondarie di Latina e provincia.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2015</h5>
          <br />
          <h6 className="interlinea-30">
            Quest’anno abbiamo messo a disposizione delle{" "}
            <span className="fucsia">classi 3°</span> delle scuole elementari di
            Latina un grande gruppo di medici Oculisti, Dentisti e Igienisti per
            educare alla prevenzione e effettuare{" "}
            <span className="fucsia">visite odontoiatriche e oculistiche</span>{" "}
            a titolo totalmente gratuito per tutti i bambini.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2014</h5>
          <br />
          <h6 className="interlinea-30">
            I piccoli pazienti hanno seguito un{" "}
            <span className="fucsia">percorso didattico di salute</span>,
            durante il quale sono stati istruiti sulle norme di igiene orale e
            alimentare.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              In questi anni sono stati investiti 20.000 € e sono state fatte
              2300 visite pediatriche
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Sorriso;
