import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Meningite = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Informiamo sulla meningite
            </h1>
            <br />
            <h4>Combattiamo insieme la malattia </h4>
            <br />
            <h6>6 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/meningite.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Il tema della meningite è fortemente sentito dalla nostra
            associazione. è stata proprio questa malattia, infatti, a portarci
            via la piccola Alessia e a stravolgere, di conseguenza, le nostre
            vite. Per questo motivo abbiamo deciso di investire ogni anno le
            nostre energie e il nostro cuore nella tutela dell’infanzia,
            organizzando attività e progetti di educazione e informazione sulla
            meningite. Si tratta di eventi rivolti sia ai più piccoli che ai
            loro genitori, i quali hanno un ruolo fondamentale nella lotta a
            questa malattia.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">I PROGETTI</h3>
          <br />
          <h3 className="arancione arima-bold">
            M TEAM: CONTRO LA MENINGITE PER LA SALUTE DEI NOSTRI BAMBINI (2019)
          </h3>
          <br />
          <h6 className="interlinea-30">
            Nel 2019 è nata la <span className="fucsia">M-team</span>, una
            squadra di mamme testimonial a supporto della campagna di{" "}
            <span className="fucsia">prevenzione</span> e{" "}
            <span className="fucsia">sensibilizzazione</span> avviata dal
            comitato nazionale "Liberi dalla Meningite”. Lo scopo di questa
            campagna è quello di fare informazione su questa gravissima
            infezione, molto frequente nei bambini piccoli.
            <br />
            <br />
            Le componenti di questa grande squadra sono: la campionessa{" "}
            <span className="fucsia">Tania Cagnotto</span>, la food blogger e
            imprenditrice <span className="fucsia">Sonia Peronaci</span>, la
            Dott.ssa <span className="fucsia">Elena Bozzola</span>, segretaria
            nazionale SIP (Società Italiana di Pediatria) e la nostra{" "}
            <span className="fucsia">Amelia Vitiello</span>, presidente
            dell’associazione “Alessia e i suoi Angeli” e del “Comitato
            Nazionale Liberi dalla Meningite”.
            <br />
            <br />
            L’obiettivo finale di questa campagna è quello di{" "}
            <span className="fucsia">
              migliorare la salute dei bambini a 360°
            </span>
            , aiutando i genitori a capire cosa fare per salvaguardare il
            benessere psicofisico dei loro bambini e bambine. Ecco perché i temi
            trattati in questo percorso di educazione sono la{" "}
            <span className="fucsia">
              vaccinazione, la sana alimentazione e l’attività fisica.
            </span>
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">MISSING B (2019)</h3>
          <br />
          <h6 className="interlinea-30">
            “Missing B” è la{" "}
            <span className="fucsia">
              campagna europea di sensibilizzazione
            </span>{" "}
            sul meningococco B, sostenuta a livello mondiale da GSK. L’obiettivo
            è quello di trasmettere ai genitori l’importanza di vaccinare i
            propri figli.
            <br />
            <br />
            L’iniziativa è realizzata con l’autorizzazione del ministero della
            Salute e il patrocinio del nostro Comitato nazionale contro la
            Meningite (Liberi dalla Meningite), Sip (Società Italiana di
            Pediatria), Siti (Società Italiana di igiene, Medicina Preventiva e
            Sanità Pubblica), Fimp (Federazione Italiana Medici Pediatri), Fimmg
            (Federazione Italiana Medici di Medicina Generale) e Wfpha (World
            Federation of Public Health Associations).
            <br />
            <br />
            Anche in questa attività, la nostra associazione ha contribuito con
            la partecipazione della nostra vicepresidente Amelia Vitiello.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">IMMUNIZZIAMO (2019,2018)</h3>
          <br />
          <h6 className="interlinea-30">
            Si tratta di un{" "}
            <span className="fucsia">progetto di educazione</span> che ha visto
            come protagonisti Amelia Vitiello, vicepresidente della nostra
            associazione, il Prof. Giuseppe La Torre, presidente della Facoltà
            di Igiene dell’Università “La Sapienza” di Roma e la Dott.ssa Rita
            Carsetti del Bambin Gesù.
            <br />
            <br />
            In collaborazione con il Museo Explora di Roma nel 2018 e la
            Biblioteca Scolastica Multimediale Territoriale ’Elisabetta Patrizi’
            nel 2019, è stato possibile svolgere delle{" "}
            <span className="fucsia">attività</span> destinate ai bambini e alle
            bambine e dei <span className="fucsia">talk</span> rivolti alle
            famiglie, il cui tema principale era la{" "}
            <span className="fucsia">vaccinazione</span>.
            <br />
            <br />
            L’obiettivo di “Immunizziamo” era quello di{" "}
            <span className="fucsia">
              trasmettere l’importanza dell’igiene, far scoprire il mondo dei
              batteri e dei virus
            </span>{" "}
            e il concetto dell’
            <span className="fucsia">immunità di gregge</span>. Per quanto
            riguarda le attività dedicate ai più piccoli, sono stati organizzati
            dei <span className="fucsia">laboratori</span> ai quali hanno
            partecipato più di 200 bambini e bambine.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">
            GLI ALTRI: CORTOMETRAGGIO SULLA MENINGITE (2018)
          </h3>
          <br />
          <h6 className="interlinea-30">
            “Gli altri” è un <span className="fucsia">cortometraggio</span>{" "}
            ideato dalla nostra vicepresidente Amelia Vitiello, il cui obiettivo
            è quello di <span className="fucsia">sensibilizzare</span> le
            persone al <span className="fucsia">vaccino</span> contro la
            meningite.
            <br />
            <br />
            Il cortometraggio è stato realizzato dal comitato nazionale contro
            la Meningite, di cui Amelia è presidente, in collaborazione con la
            nostra associazione e con il patrocinio di Ministero della Salute,
            Sip, Fimp, Siti e Simeup.
            <br />
            <br />
            La protagonista è proprio Amelia che interpreta sé stessa nella
            figura di una madre che, attraverso la sua sventura, cerca di
            trasferire il{" "}
            <span className="fucsia">valore unico della prevenzione</span> nei
            confronti di due giovani genitori, i quali sono in contrasto su
            quali scelte prendere per il proprio bambino appena nato.
            <br />
            <br />
            Il corto è stato presentato il 30 novembre a Milano durante l’evento{" "}
            <span className="fucsia">“Gli altri siamo noi”</span>. L’evento è
            avvenuto presso lo Spazio Gessi, con la collaborazione di Adnkronos
            Comunicazione.
            <br />
            <br />
            Se ti va, dai un’occhiata al filmato:{" "}
            <a
              href="https://www.youtube.com/watch?v=MXApub6g8mo"
              target="_blank"
              rel="noreferrer"
            >
              clicca qui.
            </a>
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">WORLD MENINGITE’S DAY (2016)</h3>
          <br />
          <h6 className="interlinea-30">
            La <span className="fucsia">“World Meningite’s day”</span> (giornata
            mondiale contro la meningite che si celebra ogni 24 aprile)
            rappresenta per la nostra associazione l’occasione giusta per fare
            informazione su questa malattia.
            <br />
            <br />
            In questa occasione, infatti, è stata fatta una{" "}
            <span className="fucsia">
              campagna di sensibilizzazione e informazione
            </span>{" "}
            promossa dal Comitato Nazionale contro la meningite e
            dall’associazione Onlus di Latina “Alessia e i suoi Angeli”.
            <br />
            <br />
            L’obiettivo di questa campagna era quello di{" "}
            <span className="fucsia">fornire</span> ai genitori{" "}
            <span className="fucsia">tutti gli strumenti</span> per scegliere in
            modo libero e consapevole come{" "}
            <span className="fucsia">vaccinare</span> i propri figli e le
            proprie figlie.
            <br />
            <br />
            Come sostiene Amelia “bisogna prendere atto che la meningite è
            un’infezione potenzialmente letale, che può capitare all’improvviso,
            e anche se apparentemente i numeri paiono risicati in realtà le
            implicazioni sono dolorosissime, perché possono portare alla morte o
            all’invalidità permanente. Per questo motivo ci appelliamo alle
            istituzioni affinché facciano fronte alla meningite adottando misure
            precise e puntuali e politiche di prevenzione e diagnosi tempestive
            omogenee su tutto il territorio nazionale”.
            <br />
            <br />
            Per rilanciare questo messaggio abbiamo scelto un palcoscenico di{" "}
            <span className="fucsia">sport, infanzia e divertimento</span>: il
            torneo di calcio “Mediolanum Baby Cup”.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">
            HAPPY RUN E CONCORSO FOTOGRAFICO OLIM PALUS (2015)
          </h3>
          <br />
          <h6 className="interlinea-30">
            Lo <span className="fucsia">sport</span> è al centro delle attività
            della nostra associazione, per il suo grandissimo potere di unire.
            Per questo motivo abbiamo organizzato una{" "}
            <span className="fucsia">
              manifestazione podistica non competitiva
            </span>{" "}
            su strada alla quale hanno partecipato circa 200 persone.
            <br />
            <br />
            L’obiettivo di questa gara era quello di sostenere il messaggio
            sociale lanciato dall’associazione “Liberi dalla meningite”​.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">
            MENINGITE: NON ABBASSIAMO LA GUARDIA! (2015)
          </h3>
          <br />
          <h6 className="interlinea-30">
            L’Università “La Sapienza” di Roma ha organizzato un convegno sulla
            meningite intitolato{" "}
            <span className="fucsia">
              “Meningite: vaccino, un’offerta di vita”
            </span>
            , in collaborazione con il comitato nazionale “Liberi dalla
            meningite” di cui Amelia è presidente. Si è trattato di un{" "}
            <span className="fucsia">incontro gratuito</span> rivolto a tutti
            quei genitori che volevano avere un confronto con la comunità
            scientifica riguardo il tema della vaccinazione, l’unica reale
            soluzione contro la meningite che purtroppo non è ancora accessibile
            a tutti.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">
            MENINGITE PER LE SCUOLE (2014, 2013)
          </h3>
          <br />
          <h6 className="interlinea-30">
            Si tratta di un <span className="fucsia">concorso creativo</span>{" "}
            promosso dalla nostra associazione e dal comitato nazionale “Liberi
            dalla meningite” in occasione del World Meningite’s day. I
            protagonisti di questa sfida eranoi ragazzi e le ragazze di alcune
            scuole medie di Roma e Latina, i quali sono stati chiamati a creare{" "}
            <span className="fucsia">poster e video</span> con l’obiettivo di
            comunicare l’
            <span className="fucsia">importanza della prevenzione</span> e della
            diagnosi tempestiva.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">
            INFORMAZIONE SANITARIA (2012)
          </h3>
          <br />
          <h6 className="interlinea-30">
            In collaborazione con il comitato nazionale contro la meningite e
            con le ASL di Latina e Roma A, abbiamo organizzato delle{" "}
            <span className="fucsia">lezioni nelle scuole</span> su questa
            terribile malattia. Nelle classi di 2ª media coinvolte è stato
            distribuito il libro “La testa a posto” nel quale sono riportate{" "}
            <span className="fucsia">informazioni scientifiche</span> in favore
            della prevenzione della meningite. Lo stesso anno, abbiamo donato{" "}
            <span className="fucsia">5000 €</span> al comitato nazionale per la
            lotta contro la meningite.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              In questi anni sono stati investiti 20.000 € nella lotta contro la
              meningite.
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Meningite;
