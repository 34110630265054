import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Ristrutturazione = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Ristrutturazione centro vaccinale
            </h1>
            <br />
            <h4>Miglioriamo gli ambienti dei bambini</h4>
            <br />
            <h6>2 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/centro_vaccinale.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            La ristrutturazione del centro vaccinale di Latina è uno dei
            progetti di cui siamo più fieri perché ha permesso di rendere un
            ambiente ambulatoriale della nostra città a misura di bambino.
            <br />
            <br />
            Questo progetto è iniziato nel 2019, anno in cui si è diffusa la
            pandemia da Covid-19. Da quel momento in poi il centro vaccinale
            avrebbe avuto un ruolo fondamentale nella lotta alla pandemia e alle
            altre patologie che ciclicamente espongono i nostri bambini.
            <br />
            <br />
            In quell’anno sono state avviate tutte le procedure amministrative e
            abbiamo ottenuto la delibera di nulla osta dell’ASL di Latina e il
            permesso dal Comune di Latina.
            <br />
            <br />
            Dopo due anni di lavori, nel 2021 è stato possibile inaugurare il
            nuovo centro vaccinale. <br />
            Ci teniamo a ricordare che l'inaugurazione ha coinciso con il
            compleanno della nostra Alessia, la quale avrebbe compiuto 15 anni.
            <br />
            <br />
            A seguito del taglio del nastro, è stato presentato il piano di
            ristrutturazione completamente finanziato dai fondi raccolti dalla
            nostra associazione attraverso 5x1000, donazioni spontanee e un
            lascito testamentario.
            <br />
            <br />
            Questo progetto non solo ha dato un nuovo aspetto estetico al
            centro, con pareti colorate e nuovi arredi, ma ha anche comportato
            un vero e proprio restyling strutturale che ha richiesto un
            investimento di 70.000 euro.
            <br />
            <br />
            Nello specifico:
            <ul>
              <li>sono stati rifatti gli impianti idrici ed elettrici</li>
              <li>
                i locali per la somministrazione dei vaccini sono passati da due
                a tre
              </li>
              <li>
                sono state abbattute le barriere architettoniche per rendere gli
                spazi accessibili a tutte le persone
              </li>
              <li>
                è stata ristrutturata la sala d’attesa e creata un’area gioco
                per i bambini e le bambine
              </li>
              <li>
                è stata creata un’area dedicata all’allattamento e alla nursery
              </li>
            </ul>{" "}
            <br />
            <br />
            Il risultato? La creazione di un ambiente a misura di bambino, con
            spazi accoglienti ma anche funzionali per le mamme e accessibili
            alle persone con disabilità.
            <br />
            <br />
            La vicepresidente della nostra associazione, Amelia Vitiello, ha
            dichiarato che "la tutela dell'infanzia è sempre stata una priorità
            per l'Associazione" e che il progetto di ristrutturazione del Centro
            Vaccinale ASL di Latina rientra proprio in questa prospettiva.
            <br />
            <br />
            La pandemia in corso ha dimostrato l'importanza dei Centri Vaccinali
            come primo presidio di contrasto alla pandemia da Covid-19, ma è
            importante non dimenticare che "altri virus non scompaiono per magia
            e che è necessario garantire la continuità del Piano Vaccinale,
            soprattutto per i più piccoli".
            <br />
            <br />
            In futuro ci auguriamo di raccogliere altri fondi necessari a
            ristrutturare anche l’area verde che si trova all’esterno del centro
            vaccinale.
          </h6>
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Ristrutturazione;
