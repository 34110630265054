import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";

const CardContatti = () => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [messaggio, setMessaggio] = useState("");
    const [load, setLoad] = useState(false);

    const inviaMessaggio = () => {
        setLoad(true);
        fetch("https://formsubmit.co/ajax/3e9647caf0ea7fad918fd47a9e75c476", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                nome: nome,
                email: email,
                messaggio: messaggio,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoad(false);
                alert(
                    "Il tuo messaggio inviato :) Grazie per averci scritto. Ti risponderemo entro 48 ore"
                );
            });
    };

    return (
        <>
            <Card style={{ maxWidth: "22rem" }} className="card-contatti">
                <Card.Body>
                    <Form.Group className="mt-4">
                        <Form.Control
                            type="text"
                            placeholder="Il tuo nome"
                            className="input-card-contatto"
                            onChange={(e) => {
                                setNome(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Control
                            type="text"
                            placeholder="La tua email"
                            className="input-card-contatto"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Il tuo messaggio"
                            className="input-card-contatto"
                            onChange={(e) => {
                                setMessaggio(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Button
                        className="bottone-card-contatto mt-4"
                        onClick={() => inviaMessaggio()}
                    >
                        {load ? <Spinner /> : "INVIA MESSAGGIO"}
                    </Button>
                </Card.Body>
            </Card>
        </>
    );
};

export default CardContatti;
