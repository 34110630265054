import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const ProntoSoccorso = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Pronto soccorso pediatrico
            </h1>
            <br />
            <h4>Garantiamo la migliore assistenza ai nostri bimbi</h4>
            <br />
            <h6>2 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/cosa_puoi_fare.webp"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Noi dell’Associazione Alessia e i suoi Angeli abbiamo da sempre un
            obiettivo ben preciso: quello di rendere migliore l’esperienza di
            vita dei bambini nei diversi ambienti da loro frequentati.
            <br />
            L’ospedale, purtroppo, può rientrare fra questi. Spesso, però, non
            rappresenta un ambiente accogliente per i più piccoli e le più
            piccole.
            <br />
            <br />
            Ciò che abbiamo voluto fare con questo progetto è stato creare uno
            spazio capace di rasserenare i bimbi in situazioni dove la serenità
            può venire meno e fornire loro la migliore assistenza pediatrica.
            <br />
            <br />
            Così, nel 2010, abbiamo donato dei fondi per inaugurare la stanza di
            prima emergenza per bambini, presso il Pronto Soccorso dell’ospedale
            S.Maria Goretti di Latina..
            <br />
            <br />
            La stanza, dotata di attrezzature mediche, è stata fornita di
            pannelli colorati e divertenti per ridurre al minimo lo stress
            psicologico dei piccoli pazienti.
            <br />
            <br />
            Purtroppo tale struttura, nel corso del tempo, non ha mai funzionato
            regolarmente, per problemi burocratici e carenze di personale.
            <br />
            <br />
            Per questo motivo abbiamo deciso di fare qualcosa affinché
            l’ospedale avesse il personale necessario a garantire la migliore
            assistenza.
            <br />
            <br />
            Così abbiamo deciso di raccogliere fondi per indire un bando per
            l’assunzione di un Pediatra da impiegare in prevalenza presso la
            stanza di osservazione pediatrica dell’ospedale di Latina.
            <br />
            <br />
            Tantissime realtà locali e singoli benefattori hanno aderito
            all’iniziativa ed oggi l’obiettivo è stato raggiunto.
            <br />
            <br />
            Ecco cosa è stato fatto anno per anno:
            <br />
            <br />
            Nel 2014 abbiamo emesso una borsa di studio destinata ad un pediatra
            specializzato in medicina d’urgenza. L'obiettivo era quello di
            garantire una presenza costante di una figura specializzata, che
            avrebbe collaborato con i colleghi già presenti al S.Maria Goretti
            per presidiare il pronto soccorso 24 ore su 24.
            <br />
            <br />
            Nel 2015, l'obiettivo è stato ampliato con l'istituzione di una
            borsa di studio e ricerca per un pediatra specializzato in Terapia
            Intensiva Neonatale. Questa borsa avrebbe permesso al pediatra di
            lavorare con il reparto di Pediatria e il pronto soccorso pediatrico
            dell'ospedale.
            <br />
            <br />
            Nel 2017, abbiamo indetto un bando di concorso per il conferimento
            di una borsa di ricerca destinata ad un medico specialista in
            Pediatria, con l'argomento "Le infezioni in età neonatale
            pediatrica: analisi dei fattori di rischio correlati allo sviluppo
            di sepsi". L'importo della borsa di ricerca era di €25.000. Il
            pediatra avrebbe lavorato presso il reparto di Pediatria
            dell'ospedale e avrebbe svolto la maggior parte delle sue attività
            presso la stanza di Pronto Soccorso Pediatrico, donata
            dall'associazione alcuni anni prima.
            <br />
            <br />
            Nel 2018, abbiamo finalmente raggiunto l'obiettivo di assumere una
            pediatra per un periodo di 12 mesi, tramite un bando a progetto. La
            Dott.ssa Emanuela Del Giudice, vincitrice del bando, ha preso
            servizio il 22 Gennaio 2018 e ha svolto la sua attività sia nel
            reparto di Pediatria che presso il Pronto Soccorso dell'ospedale,
            rendendo il servizio di assistenza più efficace.
          </h6>
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default ProntoSoccorso;
