import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalTesseramento = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="fucsia" onClick={handleShow}>
        <h5>ISCRIVITI ALL'ASSOCIAZIONE</h5>
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tesseramento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Iscriviti in questo modo</Modal.Body>
        <Modal.Footer>
          <Button
            className="bottone-rosa"
            onClick={handleClose}
            style={{ textDecoration: "none" }}
          >
            TESSERAMI
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTesseramento;
