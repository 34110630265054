import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const UltimoDivArticolo = () => {
  return (
    <div className="terzo-div-progetti">
      <Container className="text-center mb-3">
        <h1 className="text-white arima-bold">
          Il 100% dei fondi che raccogliamo e delle
          <br />
          donazioni che riceviamo vengono usati per
          <br />
          realizzare i nostri progetti
        </h1>
        <br />
        <h4 className="text-white">
          Se ti piace quello che facciamo, contribuisci anche tu alle nostre
          attività solidali
        </h4>
        <br />
        <br />
        <Link to="/cosa-puoi-fare-tu">
          <Button className="bottone-rosa">CONTRIBUISCI ANCHE TU</Button>
        </Link>
      </Container>
    </div>
  );
};

export default UltimoDivArticolo;
