import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import Footer from "../../componenti/Footer";

const ChiSiamo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="primo-div-chi-siamo text-center">
          <Container>
            <h1 className="text-white arima-bold testo-titolo-home">
              Combattiamo la sofferenza con
              <br />
              solidarietà, amore e gioia
            </h1>
            <br />
            <br />
            <Link to="/cosa-puoi-fare-tu">
              <Button className="bottone-rosa">CONTRIBUISCI ANCHE TU</Button>
            </Link>
          </Container>
        </div>
        <div className="secondo-div-chi-siamo background-fiori">
          <Container>
            <center>
              <h3 className="mb-5 arima-medium">
                LA NOSTRA STORIA, LA STORIA DI ALESSIA
              </h3>
            </center>
            <Row>
              <Col md={7}>
                <center>
                  <h5 className="interlinea-50">
                    Per conoscere l’associazione devi conoscere una storia. La
                    storia di <span className="fucsia">Amelia</span>,{" "}
                    <span className="fucsia">Alessandro</span> e{" "}
                    <span className="fucsia">Alessia</span>. Dall’amore dei
                    primi nasce, nel 2006, una splendida bambina.
                    <br />
                    <br />
                    Non appena possibile, i genitori la sottopongono a tutti i
                    vaccini disponibili. Quello della meningite B, purtroppo
                    però, non esiste ancora.
                    <br />
                    <br />
                    Il 19 ottobre del 2007 Alessia viene a mancare proprio a
                    causa di una meningite fulminante. Amelia e Alessandro
                    vengono travolti da un dolore che nessun genitore vorrebbe
                    mai provare.
                    <br />
                    Un dolore che, insieme a persone a loro vicine, hanno
                    trasformato in qualcosa di speciale.
                    <br /> Il 19 novembre del 2007, nasce l’associazione “
                    <span className="fucsia">Alessia e i suoi Angeli</span>
                    ”.
                  </h5>
                </center>
              </Col>
              <Col md={5}>
                <img
                  src="/immagini/foto-chi-siamo.webp"
                  alt="foto in bianco e nero, mamma e figlia piccola"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="terzo-div-chi-siamo">
          <Container>
            <center>
              <h3 className="mb-5 arima-medium">IL NOSTRO STATUTO</h3>
            </center>
            <center>
              <h5 className="interlinea-50">
                Alessia e i suoi Angeli è un'organizzazione non lucrativa di
                utilità sociale.
                <br /> La nostra priorità è la{" "}
                <span className="fucsia">salute</span> e lo{" "}
                <span className="fucsia">sviluppo culturale</span> di bambini,
                adolescenti e giovani che vivono in realtà di disagio,
                <br /> di emarginazione o che appartengono a popolazioni colpite
                da guerre o calamità naturali.
                <br />
                Se vuoi, trovi il nostro statuto{" "}
                <a
                  href="/documenti/statuto.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  qui
                </a>
              </h5>
            </center>
          </Container>
        </div>
        <div className="quarto-div-chi-siamo background-fiori">
          <Container>
            <center>
              <h3 className="mb-5 arima-medium">LA SQUADRA</h3>
            </center>
            <Row>
              <Col md={3}>
                <center>
                  <img
                    src="/immagini/team1-chi-siamo.png"
                    alt="foto in bianco e nero, mamma e figlia piccola"
                    style={{ maxWidth: "180px" }}
                  />
                  <h4 className="arima-medium mt-3">Alessandro M.</h4>
                  <h5>Prsidente</h5>
                  <br />
                </center>
              </Col>
              <Col md={3}>
                <center>
                  <img
                    src="/immagini/team2-chi-siamo.png"
                    alt="foto in bianco e nero, mamma e figlia piccola"
                    style={{ maxWidth: "180px" }}
                  />
                  <h4 className="arima-medium mt-3">Amelia V.</h4>
                  <h5>Vicepresidente</h5>
                  <br />
                </center>
              </Col>
              <Col md={3}>
                <center>
                  <img
                    src="/immagini/team3-chi-siamo.png"
                    alt="foto in bianco e nero, mamma e figlia piccola"
                    style={{ maxWidth: "180px" }}
                  />
                  <h4 className="arima-medium mt-3">Luca G.</h4>
                  <h5>Tesoriere</h5>
                  <br />
                </center>
              </Col>
              <Col md={3}>
                <center>
                  <img
                    src="/immagini/team4-chi-siamo.png"
                    alt="foto in bianco e nero, mamma e figlia piccola"
                    style={{ maxWidth: "180px" }}
                  />
                  <h4 className="arima-medium mt-3">Nikolina V.</h4>
                  <h5>Consigliera</h5>
                  <br />
                </center>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <center>
                    <img
                      src="/immagini/team5-chi-siamo.png"
                      alt="foto in bianco e nero, mamma e figlia piccola"
                      style={{ maxWidth: "180px" }}
                    />
                    <h4 className="arima-medium mt-3">Cristina P.</h4>
                    <h5>Segretaria</h5>
                    <br />
                  </center>
                </div>
                <div className="d-md-none">
                  <center>
                    <img
                      src="/immagini/team5-chi-siamo.png"
                      alt="foto in bianco e nero, mamma e figlia piccola"
                      style={{ maxWidth: "180px" }}
                    />
                    <h4 className="arima-medium mt-3">Cristina P.</h4>
                    <h5>Segretaria</h5>
                    <br />
                  </center>
                </div>
              </Col>
              <Col md={4}>
                <center>
                  <img
                    src="/immagini/team6-chi-siamo.png"
                    alt="foto in bianco e nero, mamma e figlia piccola"
                    style={{ maxWidth: "180px" }}
                  />
                  <h4 className="arima-medium mt-3">Erica M.</h4>
                  <h5>Consigliera</h5>
                  <br />
                </center>
              </Col>
              <Col md={4}>
                <div className="float-start d-none d-md-block">
                  <center>
                    <img
                      src="/immagini/team7-chi-siamo.png"
                      alt="foto in bianco e nero, mamma e figlia piccola"
                      style={{ maxWidth: "180px" }}
                    />
                    <h4 className="arima-medium mt-3">Gianluca N.</h4>
                    <h5>Consigliere</h5>
                    <br />
                  </center>
                </div>
                <div className="d-md-none">
                  <center>
                    <img
                      src="/immagini/team7-chi-siamo.png"
                      alt="foto in bianco e nero, mamma e figlia piccola"
                      style={{ maxWidth: "180px" }}
                    />
                    <h4 className="arima-medium mt-3">Gianluca N.</h4>
                    <h5>Consigliere</h5>
                    <br />
                  </center>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ultimo-div-chi-siamo text-center">
          <Container>
            <h4 className="arima-medium text-white">
              TI VA DI DIVENTARE PARTE DEL GRUPPO?
            </h4>
            <br />
            <h2 className="text-white arima-bold interlinea-50">
              27 sono i soci fondatori e 178 le persone che ci
              <br />
              sostengono ogni anno
            </h2>
            <br />
            <h5 className="text-white interlinea-50">
              Ci sono diversi modi per avere un impatto positivo sulla vita
              delle persone in difficoltà. Scegliere di
              <br />
              diventare socio o socia dell'associazione, di fare una donazione o
              destinare a noi il tuo 5x1000 ne
              <br />
              sono un esempio
            </h5>
            <br />
            <br />
            <Link to="/cosa-puoi-fare-tu">
              <Button className="bottone-rosa">CONTRIBUISCI ANCHE TU</Button>
            </Link>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChiSiamo;
