import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../../componenti/Footer";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="ragazzi_home text-center">
          <Container>
            <h1 className="text-white arima-bold testo-titolo-home">
              Ci impegniamo ogni giorno
              <br />
              affinché il futuro
              <br /> di bambini e bambine sia migliore
            </h1>
            <br />
            <br />
            <Link to="/cosa-puoi-fare-tu">
              <Button className="bottone-rosa">CONTRIBUISCI ANCHE TU</Button>
            </Link>
          </Container>
        </div>
        <div className="secondo-div-home">
          <Container>
            <center>
              <h3 className="mb-5 arima-medium">Parlano di noi</h3>
            </center>
            <Row>
              <Col md="12" lg="6">
                <center>
                  <img
                    src="/immagini/repubblica.png"
                    alt="logo repubblica"
                    className="mb-5"
                  />
                </center>
              </Col>
              <Col md="12" lg="6">
                <center>
                  <img src="/immagini/latina_24.png" alt="logo latina 24" />
                </center>
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="terzo-div-home">
          <Container>
            <center>
              <h1 className="arancione arima-bold">
                Tantissimi bambini e bambine vivono situazioni di
                <br />
                disagio economico e sociale
              </h1>
              <br />
              <h5 className="interlinea-50">
                Noi dell'associazione "Alessia e i suoi Angeli" aiutiamo le
                famiglie in situazioni di difficoltà ad
                <br /> affrontare cure mediche e a sostenere spese quotidiane
                necessarie alla sopravvivenza
              </h5>
            </center>
          </Container>
        </div>
        <div className="quarto-div-home background-fiori">
          <Container>
            <center>
              <h4 className="arima-medium">PERCHÈ LO FACCIAMO</h4>
              <br />
              <h5 className="interlinea-50">
                Aiutare in maniera incondizionata è ciò che facciamo.
                <br />
                Alleviare la sofferenza di bambini e bambine di tutto il mondo è
                il nostro scopo.
                <br />
                <span className="fucsia">
                  Il sorriso sulle loro labbra è la nostra ricompensa.{" "}
                </span>
              </h5>
            </center>
          </Container>
        </div>
        <div className="quinto-div-home">
          <Container>
            <center>
              <h4 className="arima-medium">I NOSTRI PROGETTI</h4>
              <br />
              <h5 className="interlinea-50">
                <span className="fucsia">Tutti i soldi</span> che provengono
                dalle nostre attività e dalle vostre donazioni vengono investiti
                in
                <br />
                progetti solidali. Eccone alcuni
              </h5>
            </center>
          </Container>
          <br />
          <Container>
            <Row>
              <Col sm="12" md="4">
                <Card className="card-bordo-arancione mb-4">
                  <Card.Img src="/immagini/card-uova.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-medium">
                      <h4>Le uova della solidarietà</h4>
                    </Card.Title>
                    <br />
                    <Card.Text className="h5 interlinea-30 mb-4">
                      E se ti dicessimo che la cioccolata può migliorare la
                      scuola? La scuola è un ambiente...
                    </Card.Text>
                    <Link
                      to="/non-so"
                      className="fucsia"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>LEGGI</h5>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" md="4">
                <Card className="card-bordo-arancione mb-4">
                  <Card.Img src="/immagini/aiutiamo-angelo.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-medium">
                      <h4>Aiutiamo un angelo</h4>
                    </Card.Title>
                    <br />
                    <Card.Text className="h5 interlinea-30 mb-4">
                      Ogni anno destiniamo una somma di denaro a un bambino o
                      una bambina che ha bisogno di cure molto costose
                    </Card.Text>
                    <Link
                      to="/non-so"
                      className="fucsia"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>LEGGI</h5>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" md="4">
                <Card className="card-bordo-arancione mb-4">
                  <Card.Img src="/immagini/giornata-solidarieta.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-medium">
                      <h4>La giornata del sorriso</h4>
                    </Card.Title>
                    <br />
                    <Card.Text className="h5 interlinea-30 mb-4">
                      La prevenzione è importante. Per questo motivo abbiamo
                      deciso di organizzare due giornate dedicat...
                    </Card.Text>
                    <Link
                      to="/non-so"
                      className="fucsia"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>LEGGI</h5>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ultimo-div-home text-center">
          <Container>
            <h4 className="arima-medium text-white">COSA PUOI FARE TU</h4>
            <br />
            <h1 className="text-white arima-bold interlinea-50">
              È il tuo contributo che ci permette di migliorare
              <br /> l'esperienza di vita di bambini e bambine, ogni giorno
            </h1>
            <h5 className="text-white">
              Partecipa anche tu alla realizzazione dei nostri progetti e regala
              sorrisi ai più piccoli
            </h5>
            <br />
            <br />
            <Link to="/cosa-puoi-fare-tu">
              <Button className="bottone-rosa">CONTRIBUISCI ANCHE TU</Button>
            </Link>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
