import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const UnAngelo = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">Aiutiamo un angelo</h1>
            <br />
            <h4>Per alleviare la sofferenza di bambini e bambine</h4>
            <br />
            <h6>2 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/aiutiamo_un_angelo.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            In Italia tantissimi genitori non possono permettersi di pagare le
            cure che i propri piccoli e le proprie piccole necessitano. Nel caso
            delle malattie rare questa situazione è ancora più diffusa.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">IL PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            “Aiutiamo un angelo” è un progetto che ha come destinatarie tutte
            quelle famiglie che affrontano situazioni di{" "}
            <span className="fucsia">disagio economico</span> e che non riescono
            a garantire ciò di cui i loro bambini e bambine hanno bisogno.
            <br />
            <br />
            Questa iniziativa, che viene portata avanti ogni anno, nasce dalla
            volontà di{" "}
            <span className="fucsia">
              contribuire economicamente alle cure
            </span>{" "}
            di bambine e bambini affetti da{" "}
            <span className="fucsia">gravi patologie.</span>
            <br />
            <br />
            Non solo: le donazioni destinate al progetto “Aiutiamo un angelo”
            possono essere devolute a qualunque famiglia che vive{" "}
            <span className="fucsia">momenti difficili</span> e che chiede il
            nostro aiuto.
            <br />
            <br />
            Naturalmente, per tutelare i nostri donatori e donatrici, prima di
            inviare qualsiasi somma di denaro procediamo a controllare che il
            bisogno sia reale e che non ci siano truffe in atto.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">I RISULTATI</h3>
          <br />
          <h5 className="fucsia arima-bold">2019</h5>
          <br />
          <h6 className="interlinea-30">
            Abbiamo aiutato il piccolo <span className="fucsia">Diego</span> e
            la sua mamma a <span className="fucsia">lasciare il Venezuela</span>{" "}
            e a raggiungere Quito. Dopo almeno due anni di tentativi falliti di
            emigrare in un luogo pacifico e sereno dove poter ricominciare una
            nuova vita, finalmente per Erika e suo figlio Diego arriva il
            momento di intraprendere il Viaggio della Speranza. Non mancano le
            paure, le incertezze, le aspettative, ma soprattutto il peso di
            dover lasciar tutto quello che si era costruito nel corso della
            propria vita. Nonostante tutto, prevale la speranza di poter
            ricostruire le proprie vite in un luogo migliore.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2018</h5>
          <br />
          <h6 className="interlinea-30">
            Il denaro raccolto è stato devoluto in favore della ricerca per una
            malattia rarissima, la{" "}
            <span className="fucsia">Sindrome di Dravet</span>. In particolare i
            fondi sono stati donati alle sperimentazioni in atto presso
            l’ospedale <span className="fucsia">Bambin Gesù.</span>
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2017</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2017 abbiamo aiutato <span className="fucsia">Kevin</span>
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2016</h5>
          <br />
          <h6 className="interlinea-30">
            Quest’anno abbiamo finanziato l’acquisto del{" "}
            <span className="fucsia">busto ortopedico</span> alla piccola{" "}
            <span className="fucsia">Aida</span>.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2015</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2015 abbiamo contribuito alle spese mediche del piccolo{" "}
            <span className="fucsia">Sebastian di 4 anni</span>, affetto da{" "}
            <span className="fucsia">atrofia muscolare spinale di tipo 1.</span>
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2014</h5>
          <br />
          <h6 className="interlinea-30">
            Abbiamo aiutato la piccola{" "}
            <span className="fucsia">Alice, operata al cuore</span> all’ospedale
            S.Orsola di Bologna.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2013</h5>
          <br />
          <h6 className="interlinea-30">
            Abbiamo contribuito alle spese mediche del piccolo{" "}
            <span className="fucsia">Alessio</span>, un bambino di cinque anni e
            mezzo affetto da{" "}
            <span className="fucsia">Tetraparesi Distonica.</span>
            <br />
            <br />
            I medici sin dalla nascita hanno detto che non potrà né camminare,
            né parlare, ma grazie a costose ed innovative cure ora Alessio ha la
            speranza di un futuro migliore.
            <br />
            <br />
            Il nostro contributo è stato di carattere economico e di carattere
            logistico.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2012</h5>
          <br />
          <h6 className="interlinea-30">
            Quest’anno il contributo economico è stato donato in favore di{" "}
            <span className="fucsia">Gaia</span>, una bimba di 11 anni alla
            quale è stata diagnosticata una grave patologia, per la quale deve
            effettuare terapie costose.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              Dal 2012 al 2019 sono stati donati 20.000 € a bambini e bambine in
              difficoltà
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default UnAngelo;
