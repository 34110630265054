import React, { useEffect } from "react";
import Footer from "../../componenti/Footer";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const CinquePerMille = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="wrapper background-fiori">
        <div className="primo-div-cinquepermille text-center">
          <Container>
            <h1 className="text-white arima-bold testo-titolo-home">
              Dona il 5x1000 a Alessia e i suoi Angeli
            </h1>
            <br />
            <br />
            <h4 className="text-white">
              Con il tuo aiuto avremo i fondi necessari a realizzare grandi
              progetti a sostegno dell'infanzia
            </h4>
          </Container>
        </div>
        <div className="secondo-div-cinquepermille text-center">
          <Container>
            <h4 className="arima-medium">COS'È IL 5X1000</h4>
            <br />
            <h5 className="interlinea-50">
              Il 5x1000 è una parte dell'imposta IRPEF che ogni cittadino versa
              allo Stato.
              <br />
              Questa quota viene ripartita tra gli enti che svolgono{" "}
              <span className="fucsia">attività socialmente rilevanti</span>,
              come le
              <br />
              associazioni non profit o gli istituti per la ricerca scientifica
            </h5>
          </Container>
        </div>
        <div className="terzo-div-cinquepermille text-center">
          <Container>
            <h1 className="arima-medium arancione">
              Donare il 5x1000 non costa nulla
            </h1>
            <br />
            <h5 className="interlinea-50">
              Se nella Dichiarazione dei Redditi o nel Cud non indichi a quale
              ente è destinato il tuo 5x1000,
              <br />
              questo va a finire comunque nelle casse dello Stato.
              <br />
              Esprimendo semplicemente la{" "}
              <span className="fucsia">tua preferenza</span>, puoi scegliere a
              quale associazione, istituzione o<br />
              ente devolvere la tua parte di imposta
              <br />
            </h5>
          </Container>
        </div>
        <div className="quarto-div-cinquepermille text-center">
          <Container>
            <h4 className="arima-medium">COME DESTINARE IL TUO 5X1000</h4>
            <br />
            <h5 className="interlinea-50">
              È davvero semplicissimo. Ti basterà inserire il nostro codice
              fiscale <span className="fucsia">91097780596</span> e la tua firma
              nel
              <br />
              riquadro destinato alle Onlus presente nella tua Dichiarazione dei
              Redditi.
              <br />
              Il nome del riquadro è: "
              <span className="fucsia">
                Sostegno delle organizzazioni non lucrative di utilità
                sociale...
              </span>
              "
              <br />
            </h5>
            <br />
            <br />
            <br />
            <h4 className="arima-medium">COME USEREMO IL TUO 5X1000</h4>
            <br />
            <h5 className="interlinea-50">
              Il tuo 5x1000 diventerà{" "}
              <span className="fucsia">assistenza medica</span> per tutte quelle
              bambine e bambini che non possono averla. Si trasformerà in{" "}
              <span className="fucsia">attività</span> volte a sensibilizzare la
              comunità sui disagi economici e sociali che molte famiglie
              affrontano. Regalerà{" "}
              <span className="fucsia">pillole di felicità</span> a tutte quelle
              persone che, a causa di malattie o povertà, convivono con la
              sofferenza.
            </h5>
            <center>
              <br />
              <br />
              <img
                src="/immagini/cinquepermille1.png"
                className="immagini-cinquepermille mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <img
                src="/immagini/cinquepermille2.png"
                className="immagini-cinquepermille mx-4 mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <img
                src="/immagini/cinquepermille3.png"
                className="immagini-cinquepermille mb-3"
                alt="foto in bianco e nero, mamma e figlia piccola"
              />
              <br />
              <br />
              <br />
              <Link to="/i-nostri-progetti">
                <Button className="bottone-rosa">
                  LEGGI COSA ABBIAMO FATTO
                </Button>
              </Link>
            </center>
          </Container>
        </div>
        <div className="quinto-div-cinquepermille text-center">
          <Container>
            <h1 className="arima-medium arancione">
              Dobbiamo ringraziare 254 persone
            </h1>
            <br />
            <h5 className="interlinea-50">
              che lo scorso anno hanno scelto di sostenere Alessia e i suoi
              Angeli donando il loro 5x1000.
              <br />
              Contribuisci anche tu alle nostre attività. Donare il 5x1000 è
              semplice e gratuito
            </h5>
          </Container>
        </div>
        <div className="sesto-div-cinquepermille text-center">
          <Container>
            <h4 className="arima-medium">ALTRI MODI PER DONARE</h4>
            <br />
            <h5 className="interlinea-50">
              Ci sono molti modi per contribuire alle nostre attività di
              solidarietà e beneficenza. Scegli tu in che modo sostenerci
            </h5>{" "}
            <br />
            <br />
            <br />
            <Link to="/cosa-puoi-fare-tu">
              <Button className="bottone-rosa">SCOPRI COME CONTRIBUIRE</Button>
            </Link>
            <br />
            <br />
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CinquePerMille;
