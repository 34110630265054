import React, { useEffect } from "react";
import Footer from "../../componenti/Footer";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalTesseramento from "./ModalTesseramento";

const CosaPuoiFare = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="primo-div-cosapuoifare text-center">
          <Container>
            <h1 className="text-white arima-bold testo-titolo-home">
              Il tuo contributo è fondamentale
            </h1>
            <br />
            <br />
            <h4 className="text-white">
              Insieme possiamo salvare la vita di tantissimi angeli e aiutare le
              famiglie meno abbienti a sostenere <br />
              le spese che non si possono permettere
            </h4>
          </Container>
        </div>
        <div className="secondo-div-cosapuoifare background-fiori">
          <Container>
            <center>
              <h1 className="arima-medium arancione">
                Qualsiasi contributo, anche piccolo, può regalare
                <br />
                grandi sorrisi
              </h1>
              <br />
              <h5 className="interlinea-50">
                Ci sono molti modi per aiutare tutti quei bambini e quelle
                bambine che si trovano in situazioni di
                <br />
                degrado, abbandono e malattia. Se vuoi, scegli come fare la tua
                parte
              </h5>
              <br />
              <br />
              <br />
              <h4 className="arima-medium">ECCO COME PUOI CONTRIBUIRE</h4>
              <br />
            </center>
            <Row>
              <Col xs={12} md={6}>
                <Card
                  className="card-bordo-arancione float-end mb-5"
                  style={{ maxWidth: "28rem" }}
                >
                  <Card.Img src="/immagini/card5permille.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-bold mb-4">
                      <h4>5X1000</h4>
                    </Card.Title>
                    <Card.Text className="h5 interlinea-30 mb-4">
                      Scegliere di devolvere il tuo 5x1000 ad Alessia e i suoi
                      Angeli non costa nulla. Ti basterà inserire il nostro
                      codice fiscale
                    </Card.Text>
                    <Link
                      to="/cinque-per-mille"
                      className="fucsia"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>SCOPRI COME FARE</h5>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card
                  className="card-bordo-arancione mb-5"
                  style={{ maxWidth: "28rem" }}
                >
                  <Card.Img src="/immagini/carddonazione.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-bold mb-4">
                      <h4>Donazioni online</h4>
                    </Card.Title>
                    <Card.Text className="h5 interlinea-30 mb-4">
                      Paypal o carta: in 3 semplici mosse puoi diventare una
                      parte fondamentale delle nostre attività in Italia e nel
                      mondo
                    </Card.Text>
                    <a
                      href="https://www.paypal.com/donate?token=-p1RCfpUtPr70SE2WrDcF88tZLrCgQ5RqwA2Bb1w0Piz2XXDYHZeQ7JlnDnMlv9bzgVNsEmYkAFSZ1YY"
                      target="_blank"
                      className="fucsia"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>DONA ORA</h5>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card
                  className="card-bordo-arancione float-end mb-5"
                  style={{ maxWidth: "28rem" }}
                >
                  <Card.Img src="/immagini/cardtesseramento.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-bold mb-4">
                      <h4>Tesseramento</h4>
                    </Card.Title>
                    <Card.Text className="h5 interlinea-30 mb-4">
                      Se ti piace quello che facciamo, entra a far parte del
                      gruppo con una donazione costante. Abbiamo bisogno di te
                    </Card.Text>
                    <ModalTesseramento />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card
                  className="card-bordo-arancione mb-5"
                  style={{ maxWidth: "28rem" }}
                >
                  <Card.Img src="/immagini/cardludoteca.png" />
                  <Card.Body>
                    <br />
                    <Card.Title className="arima-bold mb-4">
                      <h4>Ludoteca "Alessia e i suoi Angeli"</h4>
                    </Card.Title>
                    <Card.Text className="h5 interlinea-30 mb-4">
                      La nostra ludoteca è pronta ad ospitare i tuoi eventi. Il
                      prezzo che pagherai sarà investito interamente in progetti
                      solidali
                    </Card.Text>
                    <Link
                      to="/ludoteca"
                      className="fucsia"
                      style={{ textDecoration: "none" }}
                    >
                      <h5>SCOPRI DI PIÙ</h5>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <br />
            <center>
              <h4 className="arima-medium">TESTIMONIANZE</h4>
              <br />
              <h5 className="interlinea-50">
                Durante questi anni fatti di dedizione e solidarietà abbiamo
                incontrato tante persone che hanno
                <br />
                deciso di sostenere i nostri progetti.
                <br />
                Debora, Elena, Denny e Manuela hanno un messaggio per te:{" "}
                <a href="https://google.com" target="_blank" rel="noreferrer">
                  guarda i loro video.
                </a>
                <br />
              </h5>
            </center>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CosaPuoiFare;
