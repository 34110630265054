import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../../componenti/Footer";
import UltimoDivArticolo from "./UltimoDivArticolo";

const Saint = () => {
  return (
    <>
      <div className="wrapper background-fiori">
        <Container>
          <center>
            <br />
            <br />
            <br />
            <br />
            <h1 className="arima-bold testo-titolo-home">
              Aiutiamo l’ospedale Saint Damien, in Madagascar
            </h1>
            <br />
            <h4>Tutti hanno diritto a essere curati</h4>
            <br />
            <h6>3 minuti di lettura</h6>
            <br />
            <br />
          </center>
        </Container>
        <Container fluid>
          <center>
            <img
              src="/immagini/articoli/damien.jpg"
              alt="Uova"
              className="immagine-articolo"
            />
          </center>
        </Container>
        <br />
        <br />
        <Container fluid="sm">
          <h6 className="interlinea-30">
            Noi dell’associazione “Alessia e i suoi Angeli” abbiamo un
            obiettivo: portare solidarietà e dare sostegno, con aiuti concreti,
            laddove esistono contesti di degrado, malattia e di abbandono.
            <br />
            In Madagascar moltissime persone non possono ricevere l’assistenza
            medica di cui hanno bisogno. Ecco perché, da anni, aiutiamo
            l’ospedale Saint Damien a salvare delle vite.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">Il PROGETTO</h3>
          <br />
          <h6 className="interlinea-30">
            Grazie alla collaborazione con un{" "}
            <span className="fucsia">sacerdote missionario</span> di Latina,
            responsabile della clinica Saint Damien, abbiamo la possibilità di{" "}
            <span className="fucsia">inviare fondi</span> raccolti attraverso
            attività di beneficenza portate avanti nel nostro territorio.
            <br />
            <br />
            Il denaro inviato ogni anno permette non solo di{" "}
            <span className="fucsia">pagare il personale medico</span> o di{" "}
            <span className="fucsia">acquistare macchinari sanitari</span>, ma
            anche di organizzare delle <span className="fucsia">tournée</span>{" "}
            davvero speciali.
            <br />
            <br />I protagonisti di queste tournée sono i medici missionari che,
            grazie a un’<span className="fucsia">autoambulanza</span> da noi
            donata, raggiungono villaggi sperduti dell’isola, troppo lontani dai
            primi presidi medici.
            <br />
            <br />
            Questo è un progetto di cui andiamo davvero fieri, perché permette
            ogni anno di <span className="fucsia">salvare delle vite</span>.
            Senza queste tournée, infatti, moltissimi abitanti del Madagascar{" "}
            <span className="fucsia">
              non potrebbero raggiungere l’ospedale
            </span>{" "}
            in tempo per ricevere le cure di cui hanno bisogno. L’alternativa
            sarebbe quella di fare innumerevoli chilometri a piedi che, come
            potete immaginare, una persona in difficoltà non riuscirebbe a fare.
            <br />
            <br />
            In particolare, grazie a questo progetto, tantissime{" "}
            <span className="fucsia">mamme</span> hanno potuto e potranno{" "}
            <span className="fucsia">
              dare alla luce i loro bambini e le loro bambine
            </span>{" "}
            senza rischiare la propria vita. Dovete sapere che in Madagascar le
            donne incinte non hanno accesso alle stesse informazioni e
            possibilità che abbiamo noi in Italia: non fanno ecografie e non
            conoscono la data del parto. Se non ci fossero le tournée, queste
            donne affronterebbero un viaggio di giorni, allo scadere della
            gravidanza, per raggiungere l’ospedale.
            <br />
            <br />
            Ecco: grazie alle Jeep su cui viaggiano i medici missionari, queste
            donne possono fare delle <span className="fucsia">
              ecografie
            </span>{" "}
            direttamente nel proprio villaggio ed essere accompagnate alla
            clinica il giorno del parto. In questo modo hanno la possibilità di{" "}
            <span className="fucsia">ricevere cure mediche</span> in sicurezza e
            di{" "}
            <span className="fucsia">aumentare la propria consapevolezza</span>{" "}
            per quanto riguarda informazioni fondamentali, come, appunto, la
            data del parto.
            <br />
            <br />
            Questo progetto è andato avanti dal 2012 al 2018. Nel 2019, a causa
            della pandemia, è stato interrotto. Dal 2023 però riprenderemo a
            finanziare le tournée, continuando così ad aiutare persone in
            difficoltà.
          </h6>
          <br />
          <br />
          <h3 className="arancione arima-bold">I RISULTATI</h3>
          <br />
          <h5 className="fucsia arima-bold">2015-2018</h5>
          <br />
          <h6 className="interlinea-30">
            Ogni anno, dal 2015 al 2018, sono stati donati{" "}
            <span className="fucsia">6.000 €</span> che hanno permesso di pagare
            il <span className="fucsia">personale medico</span> della clinica
            Saint Damien per ogni intero anno solare.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2014</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2014 abbiamo inviato alla Clinique S.Damien un{" "}
            <span className="fucsia">container di aiuti umanitari</span>{" "}
            contenente: vestiario, camici, materiale didattico, 5 computer, 2
            monitor TV, una stampante, 3 radiografici, un ortopantomografo per
            il reparto odontoiatrico della clinica e altro materiale sanitario.
          </h6>
          <br />
          <br />
          <h5 className="fucsia arima-bold">2012</h5>
          <br />
          <h6 className="interlinea-30">
            Nel 2012, abbiamo contribuito a completare un container da 20 piedi
            spedito in Madagascar alla Clinique S.Damien dal Banco Tecnologico,
            Informatico e Biomedico di Peschiera Borromeo attraverso l’invio di{" "}
            <span className="fucsia">lettini ospedalieri.</span>
            <br />
            Inoltre, in collaborazione con Nettuno Photo Festival è stata
            acquistata una <span className="fucsia">centrifuga</span> e un{" "}
            <span className="fucsia">agitatore</span> per la{" "}
            <span className="fucsia">Banca del Sangue</span> dell’ospedale Saint
            Damien di Ambaja, dal valore di circa 1.590 €.
          </h6>
          <br />
          <br />
          <br />
          <center>
            <h1 className="arancione arima-bold">
              Dal 2012 al 2018 sono stati donati 20.000 €
            </h1>
          </center>
          <br />
          <br />
          <br />
        </Container>
        <UltimoDivArticolo />
      </div>
      <Footer />
    </>
  );
};

export default Saint;
