import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import classNames from "classnames";

const NavBar = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/logo.jpg"
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="justify-content-end">
            <Nav.Link
              href="/"
              className={classNames("titoli", {
                fucsia: window.location.pathname === "/",
              })}
            >
              HOME
            </Nav.Link>
            <Nav.Link
              href="/chi-siamo"
              className={classNames("titoli", {
                fucsia: window.location.pathname === "/chi-siamo",
              })}
            >
              CHI SIAMO
            </Nav.Link>
            <Nav.Link
              href="/i-nostri-progetti"
              className={classNames("titoli", {
                fucsia: window.location.pathname === "/i-nostri-progetti",
              })}
            >
              I NOSTRI PROGETTI
            </Nav.Link>
            <Nav.Link
              href="/cosa-puoi-fare-tu"
              className={classNames("titoli", {
                fucsia: window.location.pathname === "/cosa-puoi-fare-tu",
              })}
            >
              COSA PUOI FARE TU
            </Nav.Link>
            <Nav.Link
              href="/contatti"
              className={classNames("titoli", {
                fucsia: window.location.pathname === "/contatti",
              })}
            >
              CONTATTI
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
