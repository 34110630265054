import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
  <div className="footer">
    <Container>
      <br />
      <br />
      <br />
      <Row>
        <Col className="text-center">
          <h5 className="text-white interlinea-50">
            Associazione "Alessia e i suoi Angeli"
            <br />
            Largo Peri Zona Q4, Latina
          </h5>
          <br />
          <h4 className="text-white  interlinea-50">CONTATTI</h4>
          <h5 className="text-white  interlinea-50">
            alessiaeisuoiangeli@libero.it
            <br />
            +39 375 513 6338
          </h5>
        </Col>
        <Col className="text-center">
          <h4 className="text-white  interlinea-50">DOCUMENTI</h4>
          <h5 className="text-white  interlinea-50">
            <a
              href="/documenti/statuto.pdf"
              className="text-white"
              target="_blank"
              rel="noreferrer"
            >
              Lo statuto
            </a>
            <br />
            <a
              href="/documenti/privacy.pdf"
              className="text-white"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
            <br />
          </h5>
        </Col>
        <Col className="text-center">
          <h4 className="text-white  interlinea-50">SEGUICI SUI SOCIAL</h4>
          <br />
          <a href="https://www.facebook.com/alessiaeisuoiangeli">
            <img
              src="/immagini/facebook.png"
              alt="Facebook"
              className="immagine-social"
            />
          </a>
          <a href="https://www.instagram.com/alessia_e_i_suoi_angeli/">
            <img
              src="/immagini/instagram.png"
              alt="Instagram"
              className="immagine-social"
            />
          </a>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </Container>
  </div>
);

export default Footer;
